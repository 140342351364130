import React from 'react';

interface ImageDisplayProps {
  imageUrl: string;
  justify: string;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({ imageUrl, justify }) => {
  return (
    <div className="flex items-center justify-center ">
      <img src={imageUrl} alt="image" className="max-w-full max-h-full" />
    </div>
  );
};

export default ImageDisplay;

//className={`flex justify-${justify} h-screenmax-w-full max-h-full`}
