import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from 'react-router-dom';
import Copyright from './components/Copyright';
import TopMenuBar from './components/TopMenuBar';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import GamesPage from './pages/GamesPage';
import GameDetails from './pages/GameDetails';
import PrivacyPolicy from './pages/PrivacyPage';

function App() {
 

  return (
    <Router>
      <div className="min-h-screen bg-slate-100 flex flex-col">
        <TopMenuBar />
        <div className="contents">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/games" element={<GamesPage />} />
            <Route path="/games/:gameName" element={<GameDetails />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Routes>
        </div>

        <Copyright />
      </div>
    </Router>
  );
}

export default App;
